export default [

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chats',
    name: 'apps-chats',
    component: () => import('@/views/apps/chat/Chats.vue'),
    title:'چت ها',
    meta: {
      resource: 'Support',
      action: 'Support',
      pageTitle: 'چت ها',
      // breadcrumb: [
      //   {
      //     text: 'لیست',
      //     active: true
      //   },
      // ],
    },
  },
  {
    path: '/apps/chats/:id',
    name: 'apps-chats-id',
    resource: 'Support',
    action: 'Support',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      // contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  //  Ticket
  {
    path: '/apps/allTickets',
    name: 'apps-allTickets',
    component: () => import('@/views/apps/Ticket/AllTickets.vue'),
  },
  {
    path: '/apps/ticketDetail/:id',
    name: 'apps-ticketDetail-id',
    component: () => import('@/views/apps/Ticket/Detail/TicketDetail.vue'),
  },

  //  User
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  //  Financial
  {
    path: '/apps/financial/payments/list',
    name: 'apps-financial-payments-list',
    component: () => import('@/views/apps/financial/payments/PaymentsList.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },
  {
    path: '/apps/financial/payments/info/:id',
    name: 'apps-financial-payments-info',
    component: () => import('@/views/apps/financial/payments/PaymentInfo.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },
  {
    path: '/apps/orders/list',
    name: 'apps-orders-list',
    component: () => import('@/views/apps/orders/OrdersList.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },
  {
    path: '/apps/orders/info/:id',
    name: 'apps-orders-info',
    component: () => import('@/views/apps/orders/OrderInfo.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },

  //  Candles
  {
    path: '/apps/candles/list',
    name: 'apps-candles-list',
    component: () => import('@/views/apps/candles/CandlesList.vue'),
  },

  //  Trades
  {
    path: '/apps/trades/list',
    name: 'apps-trades-list',
    component: () => import('@/views/apps/trades/TradesList.vue'),
  },

  //  Wallets
  {
    path: '/apps/wallets/list',
    name: 'apps-wallets-list',
    component: () => import('@/views/apps/wallets/WalletsList.vue'),
  },
]
